import {
  InstrumentStatus,
  PaymentInstrument,
  PaymentModuleId,
} from 'main/schemas/PaymentInstrument';

export function isCard(module: PaymentModuleId): boolean {
  return (
    module === PaymentModuleId.BraintreeCard ||
    module === PaymentModuleId.WorldpayCard ||
    module === PaymentModuleId.FlutterwaveCreditCard ||
    module === PaymentModuleId.NuveiCreditCard
  );
}

export function isPayPal(module: PaymentModuleId): boolean {
  return module === PaymentModuleId.BraintreePayPal;
}

export function isImmutableDirectDebit(module: PaymentModuleId): boolean {
  return (
    module === PaymentModuleId.Sepa || module === PaymentModuleId.DirectDebitLSV
  );
}

export function isDirectDebit(module: PaymentModuleId): boolean {
  return (
    isImmutableDirectDebit(module) || module === PaymentModuleId.BraintreeSepa
  );
}

export function isGooglePay(module: PaymentModuleId): boolean {
  return module === PaymentModuleId.BraintreeGooglePay;
}

export function determineMethodPreSelection(
  preselectedPaymentMethod: PaymentModuleId | undefined,
  allowedMethods: ReadonlyArray<PaymentModuleId>
): PaymentModuleId | undefined {
  if (
    preselectedPaymentMethod &&
    allowedMethods.includes(preselectedPaymentMethod)
  ) {
    return preselectedPaymentMethod;
  } else {
    return allowedMethods[0];
  }
}

export function determineInstrumentPreSelection(
  preselectedPaymentMethod: PaymentModuleId | undefined,
  instruments: ReadonlyArray<PaymentInstrument>
): PaymentInstrument | undefined {
  const candidate = instruments
    // Find instruments for the preselected value in the intent
    .filter((i) => i.moduleId === preselectedPaymentMethod)
    // Ensure non Valid instruments are not selected
    .filter((i) => i.status === InstrumentStatus.Valid)
    .shift();

  if (!candidate) {
    // If there's no candidate, find the first valid instrument or undefined
    return instruments.filter((i) => i.status === InstrumentStatus.Valid)[0];
  }

  return candidate;
}
