import { execGet } from 'main/services/utils/RequestUtils';

import { SepaDefaultIban } from './SepaDefaultIban';

export async function getSepaDefaultIban(
  legalEntityNumber: number
): Promise<SepaDefaultIban> {
  const response = await execGet<SepaDefaultIban>(
    `sepa/defaultiban?legalEntityNumber=${legalEntityNumber}`
  );

  return response;
}
