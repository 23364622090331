import {
  OptionalStartupable,
  Startupable,
} from 'main/services/base/Startupable';
import * as StartupHelper from 'main/services/base/StartupHelper';
import BraintreeApplePayService from 'main/services/braintree/BraintreeApplePayService';
import BraintreeCardService from 'main/services/braintree/BraintreeCardService';
import BraintreeGooglePayService from 'main/services/braintree/BraintreeGooglePayService';
import BraintreePayPalService from 'main/services/braintree/BraintreePayPalService';
import LSVService from 'main/services/directdebit/LSVService';
import SepaService from 'main/services/directdebit/SepaService';
import ECommerceConnectService from 'main/services/ecommerceconnect/ECommerceConnectService';
import NavigationService from 'main/services/navigation/NavigationService';
import PaymentStore from 'main/services/payments/PaymentStore';
import SubsidiaryService from 'main/services/subsidiary/SubsidiaryService';
import TransbankService from 'main/services/transbank/TransbankService';
import TranslationsService from 'main/services/translations/TranslationsService';
import UUIDService from 'main/services/UUIDService';
import WorldpayBancontactService from 'main/services/worldpay/WorldpayBancontactService';
import WorldpayCardService from 'main/services/worldpay/WorldpayCardService';
import WorldpayIdealService from 'main/services/worldpay/WorldpayIdealService';
import { getWindowParam } from 'main/utils/url';

import BankTransferService from '../banktransfer/BankTransferService';
import BraintreeSepaService from '../braintree/BraintreeSepaService';
import PaymentOnDeliveryService from '../delivery/PaymentOnDeliveryService';
import FinvoiceService from '../finvoice/FinvoiceService';
import FlutterwaveCardService from '../flutterwave/FlutterwaveCardService';
import NuveiCardService from '../nuvei/NuveiCardService';
import NuveiTwintService from '../nuvei/NuveiTwintService';
import ProcessingKZService from '../processingkz/ProcessingKZService';
import TwoCTwoPService from '../twoctwop/TwoCTwoPService';
import YookassaService from '../yookassa/YookassaService';

// List of services we need to start the application
const PaymentServices: Startupable[] = [
  UUIDService,
  TranslationsService,
  PaymentStore,
  NavigationService,
];

// List of services that should only be started depending on the allowed payment
// methods or user available instruments that he can use.
const OptionalServices: OptionalStartupable[] = [
  BraintreeApplePayService,
  BraintreeCardService,
  BraintreeGooglePayService,
  BraintreePayPalService,

  WorldpayBancontactService,
  WorldpayCardService,
  WorldpayIdealService,

  SepaService,
  LSVService,

  SubsidiaryService,
  PaymentOnDeliveryService,
  BankTransferService,
  FinvoiceService,
  TransbankService,
  ECommerceConnectService,
  NuveiTwintService,
  FlutterwaveCardService,
  NuveiCardService,
  BraintreeSepaService,
  TwoCTwoPService,
  ProcessingKZService,
  YookassaService,
];

export type TBootstrapMode = 'payment-intent' | 'payment-manage';

class ServiceRegistrar {
  public async startServicesFor(bootstrapMode: TBootstrapMode) {
    await StartupHelper.startInSequence(PaymentServices, bootstrapMode);

    const legalEntitySearchParam = getWindowParam('le');
    const legalEntityNumber = !isNaN(Number(legalEntitySearchParam))
      ? Number(legalEntitySearchParam)
      : undefined;

    if (
      bootstrapMode === 'payment-intent' ||
      (bootstrapMode === 'payment-manage' && legalEntityNumber !== undefined)
    ) {
      await StartupHelper.startInSequence(OptionalServices, bootstrapMode);
      this.handleFailedOptionalServices();
    }
  }

  private handleFailedOptionalServices() {
    const runningPaymentModules = OptionalServices.filter((o) =>
      StartupHelper.isStartupableStarted(o)
    ).map((o) => o.moduleId);

    PaymentStore.forceUpdateInstrumentStatus(runningPaymentModules);
  }
}

export default new ServiceRegistrar();
