import AuthenticationService from '../auth/AuthenticationService';

export function getCommonAuthenticatedHeaders() {
  return authenticateHeaders({ 'Content-Type': 'application/json' });
}

function authenticateHeaders(headers: object) {
  return {
    ...headers,
    Authorization: `Bearer ${AuthenticationService.accessToken ?? ''}`,
  };
}
