import {
  ArrowIcon,
  BoldHeading,
  disableUIBlocker,
  PageLayout,
  SimpleButton,
} from 'main/components/common';
import { AlertWarning } from 'main/components/common/alerts';
import { errorToast } from 'main/components/common/toasts/utils';
import { RedirectStatus } from 'main/components/contexts/params';
import { PayButton } from 'main/components/pages/stored-intruments/utils/PayButton';
import { useUserFriendlyPaymentModuleName } from 'main/hooks/useUserFriendlyPaymentModuleName';
import { SupportedLocale } from 'main/i18n';
import { ParentComponent } from 'main/schemas/ParentComponent';
import { PaymentModuleId } from 'main/schemas/PaymentInstrument';
import AuthenticationService from 'main/services/auth/AuthenticationService';
import { setClarityTag } from 'main/services/clarity';
import SepaService from 'main/services/directdebit/SepaService';
import Logger, { LoggerOrigin } from 'main/services/Logger';
import NavigationService, {
  InternalPage,
} from 'main/services/navigation/NavigationService';
import PaymentStore from 'main/services/payments/PaymentStore';
import TelemetryService from 'main/services/telemetry/TelemetryService';
import { formatPrice } from 'main/utils/formatters';
import React, { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { SepaMandateConsentDetails } from './SepaMandateConsentDetails';

export const SepaMandateConsentPage: FC<ParentComponent> = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const paymentModuleT = useUserFriendlyPaymentModuleName();

  const [checked, setChecked] = React.useState(false);
  const [defaultSepaIban, setDefaultSepaIban] = React.useState('');
  const [error, setError] = React.useState<Error>();

  const customerNumber = AuthenticationService.user.tpId;

  if (PaymentStore.legalEntity === undefined) {
    throw new Error('legal entity shoule be set');
  }

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  useEffect(() => {
    disableUIBlocker();

    if (PaymentStore.legalEntity === undefined) {
      throw new Error('legal entity should be set');
    }

    SepaService.getSepaDefaultIban(PaymentStore.legalEntity)
      .then((iban) => {
        setDefaultSepaIban(iban.iban);
      })
      .catch((e: Error) => {
        TelemetryService.trackException(e, 'SepaMandateConsentPage');
        Logger.error(
          LoggerOrigin.ReactComponent,
          'SEPA default mandate failure',
          e
        );
        setError(e);
      });
  }, []);

  const styles = {
    pageContentHeader:
      'sticky top-0 bg-primary p-sm md:p-md 2xl:p-lg z-50 text-white',
    container: 'flex gap-4 relative focused-container items-start',
    checkbox: 'mt-2 ml-1 text-12 scale-125',
  };

  const headerText = useMemo(() => {
    return t('Total: {{totalPrice}}', {
      totalPrice: formatPrice(
        language as SupportedLocale,
        PaymentStore.currency,
        PaymentStore.orderTotal
      ),
    });
  }, [t, language]);

  if (error) {
    return (
      <AlertWarning
        title={t('Something went wrong!')}
        description={t('SEPA payments are currently unavailable.')}
      />
    );
  }

  return (
    <PageLayout
      headerDescription={
        PaymentStore.orderNumber
          ? `${t('Order')}: ${PaymentStore.orderNumber}`
          : undefined
      }
    >
      <div className={styles.pageContentHeader}>
        <BoldHeading>{headerText}</BoldHeading>
      </div>
      <div className="m-sm md:m-md">
        <div>
          <SimpleButton
            onClick={() =>
              NavigationService.navigateTo(InternalPage.PaymentMethods)
            }
          >
            <span className={'mt-2 flex flex-row items-center justify-center'}>
              <ArrowIcon direction="left" />
              <span className={'ml-xs'}>{t('Back')}</span>
            </span>
          </SimpleButton>
        </div>
        <br />
        <p>
          {t('IBAN: {{defaultSepaIban}}', { defaultSepaIban: defaultSepaIban })}
        </p>
        <p>
          {t('Customer Number: {{customerNumber}}', {
            customerNumber: customerNumber,
          })}
        </p>
        <br />
        <div className={styles.container}>
          <input
            type="checkbox"
            id="consentCheckbox"
            className={styles.checkbox}
            onChange={handleCheckboxChange}
          />
          <label htmlFor="consentCheckbox">
            <SepaMandateConsentDetails />
          </label>
        </div>
        <br />

        <div className={'flex flex-row items-center justify-center wrap'}>
          <br />
          {
            <PayButton
              title={t('Proceed')}
              disabled={!checked}
              onClick={() => {
                const currentPage = NavigationService.getInternalPage();

                NavigationService.navigateTo(InternalPage.StatusProcessing);
                const sepaMandate = PaymentStore.sepaMandate;

                if (sepaMandate === undefined) {
                  throw new Error('sepa mandate information is missing');
                }

                SepaService.submitSepaForm(
                  sepaMandate.customerName,
                  sepaMandate.iban
                )
                  .then(() => {
                    NavigationService.navigateTo(InternalPage.StatusSuccess);
                    setClarityTag('pay', [
                      RedirectStatus.Success,
                      paymentModuleT(PaymentModuleId.Sepa),
                    ]);
                  })
                  .catch((err) => {
                    NavigationService.navigateTo(currentPage);
                    TelemetryService.trackException(
                      err,
                      'SepaMandateConsentPage'
                    );
                    Logger.error(
                      LoggerOrigin.ReactComponent,
                      'Sepa Mandate creation failed',
                      err
                    );
                    errorToast(
                      t('Something went wrong!'),
                      t('Failed to authorize SEPA Mandate.')
                    );
                    setClarityTag('pay', [
                      RedirectStatus.Failure,
                      paymentModuleT(PaymentModuleId.Sepa),
                    ]);
                  });
              }}
            />
          }
        </div>
      </div>
    </PageLayout>
  );
};
