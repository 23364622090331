import cn from 'clsx';
import { disableUIBlocker } from 'main/components/common';
import { PayButton } from 'main/components/pages/stored-intruments/utils/PayButton';
import { PaymentIntentFlow } from 'main/schemas/PaymentIntent';
import AuthenticationService from 'main/services/auth/AuthenticationService';
import { getCustomerInformation } from 'main/services/customer/CustomerApi';
import NavigationService, {
  InternalPage,
} from 'main/services/navigation/NavigationService';
import PaymentStore from 'main/services/payments/PaymentStore';
import { isValidIban } from 'main/services/sepa/helpers/IbanValidationHelper';
import { SepaMandateDetails } from 'main/services/sepa/schemas/SepaMandateDetails';
import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const SepaForm: FC = () => {
  const { t } = useTranslation();

  const [accountHolderNameValue, setAccountHolderNameValue] = useState('');
  const [ibanValue, setIbanValue] = useState('');
  const [ibanValid, setIbanValid] = useState(false);

  const ibanFieldRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const customerNumber = AuthenticationService.user.tpId;
    if (customerNumber === undefined) {
      throw new Error('customerNumber is undefined!');
    }

    getCustomerInformation(customerNumber)
      .then((customer) => {
        let name = `${customer.firstName ?? ''} ${
          customer.lastName ?? ''
        }`.trim();
        if (name === '') {
          name = PaymentStore.customerName;
        }
        setAccountHolderNameValue(name);
      })
      .catch(() => {
        const name = PaymentStore.customerName;
        setAccountHolderNameValue(name);
      });

    let iban = ibanValue?.trim();
    iban = iban.replace(/\s+/g, '').toUpperCase();
    if (iban?.length <= 0) {
      markIbanFieldInvalid(ibanFieldRef);
    } else {
      if (isValidIban(iban)) {
        markIbanFieldValid(ibanFieldRef);
      } else {
        markIbanFieldInvalid(ibanFieldRef);
      }
    }

    function markIbanFieldValid(ibanRef: React.RefObject<HTMLInputElement>) {
      setIbanValid(true);
      markFieldValid(ibanRef);
    }

    function markIbanFieldInvalid(ibanRef: React.RefObject<HTMLInputElement>) {
      setIbanValid(false);
      markFieldInvalid(ibanRef);
    }
  }, [ibanValue, accountHolderNameValue]);

  function markFieldInvalid(fieldRef: React.RefObject<HTMLInputElement>) {
    fieldRef.current?.classList?.remove('border-transparent');
    fieldRef.current?.classList?.add('border-error');
  }

  function markFieldValid(fieldRef: React.RefObject<HTMLInputElement>) {
    fieldRef.current?.classList?.remove('border-error');
    fieldRef.current?.classList?.add('border-transparent');
  }

  const setMandateDetailsAndNavigateToConsent = async () => {
    disableUIBlocker();
    const sepaMandateDetails: SepaMandateDetails = {
      iban: ibanValue,
      customerName: accountHolderNameValue,
    };

    PaymentStore.sepaMandate = sepaMandateDetails;
    NavigationService.navigateTo(InternalPage.SepaMandateConsent);
  };

  return (
    <div>
      <input
        id="accountHolderName"
        type="text"
        value={accountHolderNameValue}
        className="relative rounded-field bg-secondary h-14 p-sm focused-container w-full border-2 border-transparent"
        disabled={true}
      />

      <input
        id="iban"
        placeholder="IBAN"
        type="text"
        value={ibanValue}
        onChange={(e) => setIbanValue(e.target.value)}
        className="mt-2 relative rounded-field bg-secondary h-14 p-sm focused-container w-full border-2 border-transparent"
        ref={ibanFieldRef}
      />

      <div className={cn('flex mt-md')}>
        <PayButton
          onClick={() => setMandateDetailsAndNavigateToConsent()}
          title={
            PaymentStore.flow === PaymentIntentFlow.Manage
              ? t('Add new SEPA mandate')
              : t('Proceed to pay')
          }
          disabled={!ibanValid}
        />
      </div>
    </div>
  );
};
