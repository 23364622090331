import { PaymentModuleId } from 'main/schemas/PaymentInstrument';

import { OptionalStartupable } from '../base/Startupable';
import { registerStartupable } from '../base/StartupHelper';
import { paymentInstrumentAuthorize } from '../payments/intent/PaymentIntentApi';
import PaymentStore from '../payments/PaymentStore';

class LSVService implements OptionalStartupable {
  public readonly name = 'LSVService';
  public readonly moduleId = PaymentModuleId.DirectDebitLSV;

  constructor() {
    registerStartupable(this, [PaymentStore]);
  }

  shouldStart(): boolean {
    return (
      PaymentStore.instruments.find((i) => i.moduleId === this.moduleId) !==
      undefined
    );
  }

  startup(): Promise<void> {
    return Promise.resolve();
  }

  public authorize(instrumentId: number) {
    return paymentInstrumentAuthorize({
      paymentIntentId: PaymentStore.paymentIntentId,
      paymentDataTypeId: this.moduleId,
      paymentDataId: instrumentId,
    });
  }
}

export default new LSVService();
