// Define a constant enum for country codes
enum IbanCountryCode {
  DE = 'DE', // Germany
  AT = 'AT', // Austria
  FR = 'FR', // France
  BE = 'BE', // Belgium
  LU = 'LU', // Luxembourg
  FI = 'FI', // Finland
  NL = 'NL', // Netherlands
}

// IBAN Length for each country
const IBAN_LENGTHS = {
  [IbanCountryCode.DE]: 22,
  [IbanCountryCode.AT]: 20,
  [IbanCountryCode.FR]: 27,
  [IbanCountryCode.BE]: 16,
  [IbanCountryCode.LU]: 20,
  [IbanCountryCode.FI]: 18,
  [IbanCountryCode.NL]: 18,
};

export function isValidIban(iban: string) {
  // Remove spaces and convert to uppercase
  iban = iban.replace(/\s+/g, '').toUpperCase();

  // Check if the country code is valid and the IBAN has the correct length
  const countryCode = iban.slice(0, 2) as IbanCountryCode;

  if (!(countryCode in IBAN_LENGTHS)) {
    return false;
  }

  const ibanLength = IBAN_LENGTHS[countryCode];

  if (iban.length !== ibanLength) {
    return false;
  }

  if (!isValidIBANFormat(iban, countryCode)) {
    return false;
  }

  // Rearrange IBAN for checksum validation (move first 4 characters to the end)
  const rearrangedIban = iban.slice(4) + iban.slice(0, 4);

  // Convert letters to numbers: A = 10, B = 11, ..., Z = 35
  const numericIban = rearrangedIban.replace(/[A-Z]/g, (char) =>
    (char.charCodeAt(0) - 55).toString()
  );

  // Perform the modulus 97 check
  return mod97(numericIban) === 1;
}

// Function to perform modulus 97 operation on a large number (string)
function mod97(stringValue: string) {
  let checksum = Number(stringValue.slice(0, 2)); // Convert initial checksum to a number

  for (let i = 2; i < stringValue.length; i++) {
    // Concatenate checksum and the current character, then convert to a number
    checksum = Number(String(checksum) + stringValue[i]);
    checksum = checksum % 97; // Apply the modulus operation
  }

  return checksum;
}

// Function to validate country-specific IBAN formats using regex
function isValidIBANFormat(iban: string, countryCode: IbanCountryCode) {
  switch (countryCode) {
    case IbanCountryCode.DE: // Germany
      return /^DE[0-9]{2}[0-9]{8}[0-9]{10}$/.test(iban);
    case IbanCountryCode.AT: // Austria
      return /^AT[0-9]{2}[0-9]{5}[0-9]{11}$/.test(iban);
    case IbanCountryCode.FR: // France
      return /^FR[0-9]{2}[0-9]{10}[0-9]{11}[0-9]{2}$/.test(iban);
    case IbanCountryCode.BE: // Belgium
      return /^BE[0-9]{2}[0-9]{3}[0-9]{9}$/.test(iban);
    case IbanCountryCode.LU: // Luxembourg
      return /^LU[0-9]{2}[0-9]{3}[0-9]{13}$/.test(iban);
    case IbanCountryCode.FI: // Finland
      return /^FI[0-9]{2}[0-9]{6}[0-9]{8}$/.test(iban);
    case IbanCountryCode.NL: // Netherlands
      return /^NL[0-9]{2}[A-Z]{4}[0-9]{10}$/.test(iban);
    default:
      return false; // Unsupported country code
  }
}
