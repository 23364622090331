import { PaymentsLayout } from 'main/components/common';
import { RadioGroup } from 'main/components/common/forms/inputs/RadioGroup';
import {
  BraintreeCardForm,
  PayWithBraintreePayPalBtn,
} from 'main/components/payment-providers/braintree';
import { BraintreeSepaForm } from 'main/components/payment-providers/braintree/BraintreeSepaForm';
import { PayWithBraintreeApplePay } from 'main/components/payment-providers/braintree/PayWithBraintreeApplePay';
import { PayWithBraintreeGooglePayBtn } from 'main/components/payment-providers/braintree/PayWithBraintreeGooglePayBtn';
import { PayViaECommerceConnectBtn } from 'main/components/payment-providers/ecommerceconnect';
import { PayWithFlutterwaveCardBtn } from 'main/components/payment-providers/flutterwave/PayWithFlutterwaveCardBtn';
import { NuveiCardForm } from 'main/components/payment-providers/nuvei/NuveiCardForm';
import { PayWithNuveiTwintBtn } from 'main/components/payment-providers/nuvei/PayWithNuveiTwintBtn';
import {
  PayInSubsidiaryBtn,
  PayWithBankTransferBtn,
} from 'main/components/payment-providers/offline';
import { PayOnDeliveryBtn } from 'main/components/payment-providers/offline/PayOnDeliveryBtn';
import { PayWithFinvoiceBtn } from 'main/components/payment-providers/offline/PayWithFinvoiceBtn';
import { PayWithProcessingKZBtn } from 'main/components/payment-providers/processingkz';
import { SepaForm } from 'main/components/payment-providers/sepa';
import { PayViaTransbankBtn } from 'main/components/payment-providers/transbank';
import { PayWithTwoCTwoPBtn } from 'main/components/payment-providers/twoctwop';
import {
  PayWithWorldpayBancontactBtn,
  PayWithWorldpayCardBtn,
  PayWithWorldpayIdealBtn,
} from 'main/components/payment-providers/worldpay';
import { PayWithYookassaBtn } from 'main/components/payment-providers/yookassa';
import { PaymentModuleId } from 'main/schemas/PaymentInstrument';
import { PaymentIntentFlow } from 'main/schemas/PaymentIntent';
import PaymentStore from 'main/services/payments/PaymentStore';
import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { computeRadioOptions } from './utils/helper';
import { NavigateToStoredInstrumentsPageBtn } from './utils/NavigateToStoredInstrumentsPageBtn';

export const PaymentMethodsPage: FC<{
  readonly flow: PaymentIntentFlow;
}> = ({ flow }) => {
  const { t } = useTranslation();

  const [methodToRender, setMethodToRender] = useState<PaymentModuleId>();

  const methodComponent = useMemo(() => {
    switch (methodToRender) {
      case PaymentModuleId.BraintreeApplePay:
        return <PayWithBraintreeApplePay />;
      case PaymentModuleId.BraintreeCard:
        return <BraintreeCardForm />;
      case PaymentModuleId.BraintreeGooglePay:
        return <PayWithBraintreeGooglePayBtn />;
      case PaymentModuleId.BraintreePayPal:
        return <PayWithBraintreePayPalBtn />;
      case PaymentModuleId.PaymentAtSubsidiary:
        return <PayInSubsidiaryBtn />;
      case PaymentModuleId.BankTransfer:
        return <PayWithBankTransferBtn />;
      case PaymentModuleId.Finvoice:
        return <PayWithFinvoiceBtn />;
      case PaymentModuleId.Transbank:
        return <PayViaTransbankBtn />;
      case PaymentModuleId.WorldpayBancontact:
        return <PayWithWorldpayBancontactBtn />;
      case PaymentModuleId.WorldpayCard:
        return <PayWithWorldpayCardBtn />;
      case PaymentModuleId.WorldpayIdeal:
        return <PayWithWorldpayIdealBtn />;
      case PaymentModuleId.ECommerceConnect:
        return <PayViaECommerceConnectBtn />;
      case PaymentModuleId.NuveiTwint:
        return <PayWithNuveiTwintBtn />;
      case PaymentModuleId.FlutterwaveCreditCard:
        return <PayWithFlutterwaveCardBtn />;
      case PaymentModuleId.NuveiCreditCard:
        return <NuveiCardForm />;
      case PaymentModuleId.BraintreeSepa:
        return <BraintreeSepaForm />;
      case PaymentModuleId.PaymentOnDelivery:
        return <PayOnDeliveryBtn />;
      case PaymentModuleId.TwoCTwoP:
        return <PayWithTwoCTwoPBtn />;
      case PaymentModuleId.ProcessingKZ:
        return <PayWithProcessingKZBtn />;
      case PaymentModuleId.Sepa:
        return <SepaForm />;
      case PaymentModuleId.Yookassa:
        return <PayWithYookassaBtn />;
    }
  }, [methodToRender]);

  const radioOptions = useMemo(
    () => computeRadioOptions(PaymentStore.allowedPaymentModules),
    []
  );

  //This means user landed on /manage from 'nowhere' (e.g., email) and has no stored instruments
  const isEmtyListForUndefinedCountry =
    flow === PaymentIntentFlow.Manage &&
    PaymentStore.instruments.length === 0 &&
    PaymentStore.legalEntity === undefined;

  return (
    <PaymentsLayout
      flow={flow}
      title={
        flow === PaymentIntentFlow.Manage
          ? isEmtyListForUndefinedCountry
            ? t(
                "Currently, you don't have a saved payment method. You can add one with your next online order."
              )
            : t('Add new payment method')
          : t('Select a new payment method')
      }
      footer={null}
      navigation={
        flow === PaymentIntentFlow.Moto ||
        isEmtyListForUndefinedCountry ? null : (
          <NavigateToStoredInstrumentsPageBtn />
        )
      }
      mainAction={null}
    >
      <RadioGroup
        preSelectedValue={PaymentStore.preSelectedModule?.toString()}
        name="payment-methods"
        options={radioOptions ?? []}
        onSelectionChange={(moduleIdString) =>
          setMethodToRender(Number(moduleIdString))
        }
      />
      <div className="mt-md">{methodComponent}</div>
    </PaymentsLayout>
  );
};
